import { Injectable, Signal, computed } from '@angular/core';
import { TaskSummaryDto } from '@cloudmed/resolution-services-api-client';
import { PartialStateUpdater, patchState, signalState } from '@ngrx/signals';
import { convertTaskDtoTo } from '../../../../../shared/utils/tasks.utils';

// TODO (gorodnichev): This is temp interface/type.
// We don't have client yet for all tasks. Change
// it when API will be ready
export interface TaskSummaryDtoTEMP extends TaskSummaryDto {
  assignedToName?: string;
  facilityName?: string;
  dueDate?: string;
  payerPhoneNumber?: string;
  remainingOpen?: number;
}

export interface TaskDataUiTEMP extends TaskSummaryDtoTEMP {
  patientName?: string;
}

interface State {
  data: TaskSummaryDtoTEMP[];
  loading: boolean;
}

const defaultState: State = {
  data: [],
  loading: false,
};

@Injectable({
  providedIn: 'root',
})
export class AllTasksState {
  private readonly tasksState = signalState<State>(defaultState);

  public readonly loading = this.tasksState.loading;

  public readonly tasks: Signal<TaskDataUiTEMP[]> = computed(() => {
    return this.tasksState
      .data()
      .map((task: TaskSummaryDtoTEMP) => convertTaskDtoTo(task));
  });

  public setDataState(data: TaskSummaryDtoTEMP[]) {
    patchState(this.tasksState, this.dataSetter(data));
  }

  public setLoadingState(isLoading: boolean) {
    patchState(this.tasksState, this.loadingSetter(isLoading));
  }

  private dataSetter(
    data: TaskSummaryDtoTEMP[]
  ): PartialStateUpdater<{ data: TaskSummaryDtoTEMP[] }> {
    return () => ({ data: structuredClone(data) });
  }

  private loadingSetter(
    loading: boolean
  ): PartialStateUpdater<{ loading: boolean }> {
    return () => ({ loading });
  }
}
